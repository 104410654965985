import React from 'react'
import Layout from '../components/layout'

import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import PageHeader from '../components/sections/PageHeader'
import { default as SbsIHCB } from '../components/sections/SidebysideImageHeadingContentButton'
import Values from '../components/sections/Values'
import CallToAction from '../components/sections/CallToAction'
import { Link } from 'gatsby'

export default function AboutPage(props) {
  return (
    <>
      <Layout>
        <SEO
          title="About Us | Majiska"
          description="Our team in Kuala Lumpur comprises of leading industry experts with years of experience to help your journey towards digital success." />

        <PageHeader
          heading="Your Digital Growth Partner"
          content="Majiska Digital Marketing is a digital marketing agency that has both traditional and cutting-edge digital practises at our disposal. We focus on delivering great outcomes for our respected clients, organizations, which translates into real-world impact on their bottom line. For us, results-driven commitment and advocacy have made us a powerhouse of Malaysia's digital performance agency. Always: with respect to everything we do, we focus on the foundation - return on investment (ROI)."
          imageBg={
            <StaticImage
              src="../images/kuala-lumpur-digital-vignette.jpg"
              alt="A dinosaur"
            />
          }
        />
        <main className="content-main">
          <SbsIHCB
            isEven
            isImageSquare
            heading="Our Philosophy"
            content={
              <p>
                Longevity and predictability of  success in digital marketing does not come as a result of luck;
                They are attained through dedication, clarity, and a well-defined set of values. We place a lot of importance on the environment we've cultivated at Majiska, as it is the reason for our ongoing success.
              </p>
            }
            image={<StaticImage src="../images/malaysia-digital-marketing-strategy-partner.png" alt="A dinosaur" />}
            padding="py-resp-16-24 lg:pb-0"
          />
          <Values
            heading={
              <>
                If you want to build the best wall in the world, you don't say 'I'm going to build the biggest, baddest, greatest wall that's ever been built'. You don't start there. <br /> You say 'I'm gonna lay this brick as perfectly as a brick can be laid,' and you do that every single day, and soon you have a wall.
              </>
            }
            values={[
              { name: 'Go Beyond', description: 'Surpass performance expectations', iconName: 'beyond' },
              { name: 'Delight', description: 'A true partner takes care of another', iconName: 'delight' },
              { name: 'Advance', description: 'Adapt to continuous evolvement', iconName: 'advance' },
              { name: 'Majiska Family', description: 'At the end of the day, we are people first', iconName: 'familypartner' },
            ]}
            footer={``}

          />
          <SbsIHCB
            heading="Our expertise."
            content={
              <p>
                When it comes to marketing, we lead the industry. Our founders and senior members have well over 10 years
                of <Link to="/service/seo" className="text-accent">SEO</Link>  and <Link to="/service/google-adwords-management" className="text-accent">Google AdWords</Link> management experience between them.
                We've always looked for ways to evolve our offerings beyond traditional channels, which include remarketing,
                social media and web development. These points are all the most important aspects of the modern buying cycle,
                including both online and offline marketing, are covered in our comprehensive marketing strategy.
              </p>
            }
            image={<StaticImage src="../images/malaysia-top-digital-marketing-specialists.png" alt="A dinosaur" />}
            isImageSquare
            padding="py-resp-16-24 lg:py-0"
          />
          <SbsIHCB
            heading="Trusted by the biggest, to be the best."
            content={
              <p>
                Some of the biggest companies have long placed their confidence in Majiska’s technological and quantitative analysts to shape and lead their digital development.
                In a hands-on and collaborative environment, this provides us with an opportunity to master our skills and polish our approach in the most competitive markets. Additionally, these experts deliver world-class results to businesses of all sizes. So we're always available to help our clients grow their businesses, at all levels.

              </p>
            }
            image={<StaticImage src="../images/malaysia-best-digital-marketing-company.png" quality={75} alt="A dinosaur" />}
            isEven
            padding="py-resp-16-24 lg:pt-0"
            isImageSquare
          />
          <CallToAction />
        </main>
      </Layout>
    </>
  )
}
