import * as React from 'react'
import _ from 'lodash'
import IconBox from '../IconBox'
import { IconNames } from '../SVGIcon'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade } from 'swiper'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

interface IValuesObj extends IconNames {

  name: string
  description: string
}

type ValuesProps = {
  heading?: React.ReactNode
  values: IValuesObj[]
  footer: React.ReactNode

}
export default function Values(props: ValuesProps) {
  const [swiper, setSwiper] = useState(null)
  const isDesktop = useMediaQuery({ query: '(min-device-width:1024px)' });
  return (
    <section className="section--values pt-resp-12-20">
      <div className="section--values__intro px-8 max-w-4xl mx-auto">
        {props.heading && (
          <div className="text-lg leading-normal text-center ">
            {props.heading}
          </div>
        )}
      </div>
      <div className="section--values__values-boxes container--wide px-2 lg:px-16 3xl:px-24 py-resp-12-20">
        {isDesktop ?
          <div className="values-boxes__inner flex flex-wrap mx-0 lg:-mx-3">
            {_.map(props.values, (value, i) => {

              return (
                <IconBox
                  iconName={value.iconName || 'target'}
                  key={i}
                  name={value.name}
                  description={value.description}
                />
              )
            })}
          </div> :
          <Swiper

            centeredSlides={true}
            loop={true}
            slidesPerView={1.2}
            onSwiper={s => setSwiper(s)}
            onSlideChange={() => console.log('slide change')}
            className="slider slider--values"
          >
            {_.map(props.values, (value, i) => {

              return (
                <SwiperSlide key={i}>
                  <IconBox
                    iconName={value.iconName || 'target'}
                    key={i}
                    name={value.name}
                    description={value.description}
                  />
                </SwiperSlide>
              )
            })}

          </Swiper>
        }
      </div>
      <div className="section--values__footer px-8 max-w-3xl mx-auto">
        {props.footer && (
          <div className="text-lg leading-snug text-center font-normal">
            {props.footer}
          </div>
        )}
      </div>
    </section>
  )
}
